import classNames from "classnames";

import { Header } from "../Header";
import { LayoutBase } from "../LayoutBase";
import styles from "./LayoutGray.module.scss";

interface LayoutGrayProps {
  title?: string;
  backButtonHandler?(): void;
  isBackButton?: boolean;
  isProfileButton?: boolean;
  headerClassName?: string;
  children: React.ReactNode;
  headerTitle?: string;
  className?: string;
  isCartButton?: boolean;
}

const LayoutGray = ({
  title,
  backButtonHandler,
  isBackButton = false,
  isProfileButton = false,
  headerClassName = "",
  children,
  headerTitle,
  className,
  isCartButton = true,
}: LayoutGrayProps) => {
  return (
    <LayoutBase title={title} className={classNames(styles.root, className)}>
      <Header
        backButtonHandler={backButtonHandler}
        isBackButton={isBackButton}
        isProfileButton={isProfileButton}
        className={headerClassName}
        title={headerTitle}
        isCartButton={isCartButton}
      />
      {children}
    </LayoutBase>
  );
};

export default LayoutGray;
